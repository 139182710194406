import {React, useEffect} from 'react';
import styled from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import ArticleItem from 'components/molecules/ArticleItem/ArticleItem';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router";

const helmetContext = {};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin: 0px auto 72px;
  max-width: 1920px;

  @media (max-width: 1024px) {
    width: calc(100% - 40px);
  }
`;

const ImpressumView = () => {

  const { t, i18n } = useTranslation(['impressum']);
  const navigate = useNavigate();

    const data = t('impressum', { returnObjects: true })
    const bread = t('impressum:breadcrumbs', { returnObjects: true })

    useEffect(() => {
      if (i18n.language !== 'de') {
          navigate('/');
      }
    }, [t]);

  return (
  <HelmetProvider context={helmetContext}>
      <Head
        title="Polityka Prywatności Infinisap"
        description="Wybierz karierę z Infinisap"
      />
      <NNavigation />
      <Breadcrumbs data={bread} />
      <Wrapper>
          {
              Array.isArray(data) && data?.map((item) => (
              <ArticleItem data={item} />
            ))
          }
      </Wrapper>
      <Footer />
  </HelmetProvider>
  );
};

export default ImpressumView;
